<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-volume', params: { accountId: this.$route.params.accountId } }">Volumes</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ volume.label }}</h1>
                <p class="text-caption text-center">Volume overview</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="volume">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0 mt-8">Label</p>
                    <p class="mb-0 pb-0">
                        {{ volume.label }}
                    </p>

                    <p class="text-overline mb-0 mt-8">Volume ID</p>
                    <p class="mb-0 pb-0">
                        {{ volume.id }}
                    </p>

                    <p class="text-overline mb-0 mt-8">Allocated size</p>
                    <p class="mb-0 pb-0">
                        {{ allocated }}
                    </p>

                    <p class="text-overline mb-0 mt-8">Usage</p>
                    <p class="mb-0 pb-0">
                        {{ usage }}
                        <!-- NOTE: these units are displayed in SI; see also https://www.nist.gov/pml/weights-and-measures/metric-si-prefixes and https://physics.nist.gov/cuu/Units/binary.html -->
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn icon @click="checkVolumeUsage" v-bind="attrs" v-on="on">
                                    <font-awesome-icon :icon="['far', 'sync-alt']"/>
                                </v-btn>
                            </template>
                            Refresh
                        </v-tooltip>
                    </p>

                    <template v-if="volume.website_id">
                    <p class="text-overline mb-0 mt-8">Website</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: volume.website_id } }">
                            <span v-if="website">{{ website.label }}</span>
                            <span v-if="!website">Website</span>
                        </router-link>
                    </p>

                    <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-volume-access', params: { accountId: this.$route.params.accountId, volumeId: this.$route.params.volumeId } }">Access control</router-link>
                    </p>

                    </template>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
// import { mapState } from 'vuex';
// import VolumeList from '@/components/account-dashboard/VolumeList.vue';

// based on code by Andrews Lundblad (https://programming.guide/java/formatting-byte-size-to-human-readable-format.html)
function humanReadableByteCountSI(bytesInput) {
    let bytes = bytesInput;
    if (bytes > -1000 && bytes < 1000) {
        return `${bytes} B`;
    }
    const units = ['k', 'M', 'G', 'T', 'P', 'E'];
    let i = 0;
    while (bytes <= -999950 || bytes >= 999950) {
        bytes /= 1000;
        i += 1;
    }
    bytes /= 1000.0;
    return `${bytes.toFixed(1)} ${units[i]}B`;
}

export default {
    components: {
        // VolumeList,
    },
    data: () => ({
        account: null,
        volume: null,
        website: null,
        error: null,
    }),
    computed: {
        // ...mapState({
        //     session: (state) => state.session,
        // }),
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.volume !== null;
        },
        allocated() {
            if (typeof this.volume?.allocated_gb === 'number') {
                return `${this.volume.allocated_gb} GB`;
            }
            return 'Unknown';
        },
        usage() {
            if (typeof this.volume?.usage === 'number') {
                return humanReadableByteCountSI(this.volume.usage);
            }
            return 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadVolume() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadVolume: true });
                const response = await this.$client.account(this.$route.params.accountId).volume.get(this.$route.params.volumeId);
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.volume = response;
                    if (this.volume.website_id) {
                        this.loadWebsite();
                    }
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load volume');
                }
            } catch (err) {
                console.error('failed to load volume', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadVolume: false });
            }
        },
        async loadWebsite() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadWebsite: true });
                const response = await this.$client.account(this.$route.params.accountId).website.get(this.volume.website_id);
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.website = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load website');
                }
            } catch (err) {
                console.error('failed to load website', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadWebsite: false });
            }
        },
        async editVolume() {
            try {
                this.error = false;
                this.$store.commit('loading', { editVolume: true });
                const response = await this.$client.account(this.$route.params.accountId).volume.edit({ id: this.$route.params.volumeId }, { test: 'foo' });
                console.log(`editVolume: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'OK' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit volume' });
                }
            } catch (err) {
                console.error('failed to edit volume', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit volume' });
            } finally {
                this.$store.commit('loading', { editVolume: false });
            }
        },
        async checkVolumeUsage() {
            try {
                this.error = false;
                this.$store.commit('loading', { checkVolumeUsage: true });
                const response = await this.$client.account(this.$route.params.accountId).volume.check(this.$route.params.volumeId, { item: 'usage' });
                console.log(`checkVolumeUsage: response ${JSON.stringify(response)}`);
                if (response?.volume) {
                    this.volume = response.volume;
                }
                // if (response?.isEdited) {
                //     this.$bus.$emit('snackbar', { type: 'error', headline: 'OK' });
                // } else {
                //     this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check volume' });
                // }
            } catch (err) {
                console.error('failed to check volume', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check volume usage' });
            } finally {
                this.$store.commit('loading', { checkVolumeUsage: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadVolume();
    },
};
</script>
